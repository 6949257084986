.header-tabs{
    display: inline-block;
    height: 74px;
    padding: 11px;
    padding-left: 20px;
}

.header-container{
    width: 100%;
    display: block;
    box-shadow: 0px 4px 9px 0px #0000001f;
}
.header-user-menu{
    display: inline-block;
    padding: 10px 4px 4px 4px;
    float: right;
    text-align: right;
}
.header-logo{
    height: 37px;
    width: 138px;
    vertical-align: top;
    margin-right: 25px;
    margin-top: 8px;
}

.header-menu-button{
    margin-left: 30px;
}
.header-search{
    vertical-align: middle;
}
.header-menu-button-burger{
    display: inline-block;
    float: right;
}
.header-menu-button-burger svg:hover{
    color: #3B383E;
}
.ant-drawer-close{
    height: 54px;
    border-left: 1px solid #727272;
}
.header-dropdown-menu-container{

}
.header-dropdown-menu-list{
    display: inline-block;
    width: 75%;
    height: 250px;
}
.header-dropdown-menu-links{
    width: 100%;
    padding: 10px 0;
}
.header-dropdown-menu-links span{
   /* font-family: Roboto;*/
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.115em;
    text-align: left;
    color: #FFFFFF
}
.header-dropdown-menu-links span:hover {
    color: red;
}
.header-tabs-position{
    display: inline-block;
}
.ant-drawer-header{
    padding: 5px;
}
.header-menu-dropdown-close button{
    border-left: 1px solid #727272;
    height: 100%;
    padding: 10px;
}
.lang-container{
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding-top: 10px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.115em;
    text-align: left;
    color: #FFFFFF;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.lang-item{
    width: 100%;
    display: inline-block;
}
.lang-item img{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    width: 41px;
    height: 21px;
    vertical-align: revert;
    padding-right: 10px;
}
.lang-item:hover{
    color: red;
    cursor: pointer;
}
.lang-selected{
    color: red;
}
.header-tab-menu-selected{
    width: 484px;
    position: absolute;
    top: -5px;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 400;
    line-height: 40px;
}
.lang-selected img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}
@media (max-width: 846px) {
    .header-menu-button{
        margin-left: 3px;
    }
}
@media (max-width: 800px) {
    .header-tabs-position{
        width: 100%;
        display: none;
    }
}
