.error_handler_container{
  width: 100%;
  height: 70vh;
  text-align: center;
  padding: 20% 20% 20% 20%;
}
.error_handler_description{
  width: 100%;
  display: inline-block;
  margin-bottom: 10px
}