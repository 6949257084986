body{
    font-family: Roboto, sans-serif;
}
input[type = 'text'] {
    padding: 10px;
}
input[type = 'password'] {
    padding: 10px;
}
input{
    padding: 10px;
}
.ant-tabs-ink-bar {
    background: red;
}
.ant-tabs-tab{
    color: #3B383E;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    font-family: Roboto, sans-serif
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: red;
}
.ant-drawer-close{
    display: none;
}
.ant-drawer-body{
    overflow: unset;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red;
}
.content{
    color: #3B383E;
}
.ant-tabs-tab:hover{
    color: red;
}
.ant-select-selection-placeholder{
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #3B383E;
}
/*.ant-btn-icon-only:hover{
    border-color: red;
}*/
.ant-input-number-input-wrap{
    padding: 5px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 43px;
    padding: 7px;
}
.ant-select-selector{
    height: 43px;
    padding: 7px;
}
.loading-spinner{
    width: 100%;
    height: 100vh;
    padding: 45vh 0;
}
.ant-spin-dot-item{
    background-color: red;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    width: 100%;
    display: inline-block;
}
/*.mainContainer{
    text-align: center;
}
.content{
    width: 1200px;
    text-align: left;
    display: inline-block;
}*/
@media (max-width: 802px) {
    .ant-btn {
        white-space: unset;
    }
}